import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import PrivacyPolicy from "./features/privacyPolicy/PrivacyPolicy";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route
          path="/privatumo-ir-slapuku-politika"
          element={<PrivacyPolicy />}
        />
        <Route path="/*" element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
