import Heading from "../heading/Heading";
import Footing from "../footing/Footing";
import Section from "../../components/Section";

const PrivacyPolicy = () => {
  return (
    <>
      <Heading />
      <Section>
        <h3>Privatumo ir slapukų politika</h3>
        <p>Paskutinį kartą atnaujinta: 2024 m. lapkričio 18 d.</p>
        <h4>Kaip turėtumėte skaityti šią privatumo politiką?</h4>
        <p>
          Šioje privatumo ir slapukų politikoje (toliau – Privatumo politika)
          rasite atsakymus į svarbiausius klausimus apie tai, kaip renkame,
          naudojame ir saugome informaciją apie jus. Taip pat paaiškiname,
          kokias teises turite dėl mums pateiktų asmens duomenų. Jums tai
          svarbu, todėl atidžiai perskaitykite šią Privatumo politiką.
        </p>
        <p>
          Ši Privatumo politika taikoma naudojimuisi svetaine ir mūsų
          teikiamomis paslaugomis bei parduodamomis Prekėmis (toliau –
          Paslaugos).
        </p>
        <h4>Kas mes esame?</h4>
        <p>
          Duomenų valdytojas yra UAB „Gasefis”, juridinio asmens kodas
          303575354, adresas J. Franko g. 12-14, LT-08431, Vilnius, Lietuvos
          Respublika, el. pašto adresas info@kipos.lt (<strong>toliau</strong> –{" "}
          <strong>mes</strong>).
        </p>
        <h4>Kaip tvarkome jūsų asmens duomenis?</h4>
        <p>
          Jūsų asmeninę informaciją tvarkome dėl įvairių priežasčių,
          priklausomai nuo to, kokias Paslaugas Jums teikiame, įskaitant:
        </p>
        <ul>
          <li>
            Prekių ir (arba) paslaugų teikimo, mokėjimų administravimo tikslais:
            (i) siekiant pristatyti ir palengvinti Paslaugų teikimą jums; (ii)
            siekiant įvykdyti ir administruoti jūsų užsakymus.
          </li>
          <li>
            Tiesioginės rinkodaros tikslais: siųsti jums rinkodaros ir
            reklaminius pranešimus.
          </li>
          <li>
            Konkursų, akcijų, žaidimų organizavimo ir vykdymo tikslais: (i)
            organizuoti konkursus, akcijas, žaidimus; (ii) išrinkti nugalėtoją,
            susisiekti su juo, apdovanoti.
          </li>
          <li>
            Aptarnavimo kokybės vertinimo, tobulinimo ir užtikrinimo tikslais:
            (i) prašyti grįžtamojo ryšio; (ii) nustatyti naudojimo tendencijas.
          </li>
          <li>
            Kilusių ginčų su naudotojais sprendimo tikslais: (i) atsakyti į
            naudotojų užklausas; (ii) pasiūlyti naudotojams pagalbą.
          </li>
        </ul>
        <h4>Kokius asmens duomenis tvarkome?</h4>
        <p>
          Mes renkame asmeninę informaciją, kurią savanoriškai pateikiate mums,
          kai registruojatės Paslaugoms, išreiškiate susidomėjimą gauti
          informacijos apie mus arba mūsų produktus ir Paslaugas, kai
          dalyvaujate vykdomoje veikloje, susijusioje su Paslaugomis, arba
          kitaip, kai su mumis susisiekiate.
        </p>
        <p>Asmeninė informacija, kurią renkame, gali būti tokia:</p>
        <ul>
          <li>
            <strong>Naudotojo duomenys:</strong> vardas, pavardė, el. pašto
            adresas, Tel. Nr., adresas.
          </li>
          <li>
            <strong>Mokėjimo duomenys:</strong> elektroninės bankininkystės
            informacija (banko pavadinimas ar kt.), užsakymo/atsiėmimo adresas,
            užsakymo/atsiėmimo Tel. Nr., užsakymo/atsiėmimo el. pašto adresas.
          </li>
          <li>
            <strong>Prekių/paslaugų krepšelio duomenys:</strong> mokėjimų
            istorija, vartotojo mokėjimo profilis (profiliavimas, t. y. naršymo
            elgsenos, mokėjimo įpročių, interesų ar kt. tvarkymas), turimų
            prekių/paslaugų informacija.
          </li>
          <li>
            <strong>Įrenginio duomenys:</strong> įrenginio tipas (Telefonas,
            kompiuteris, planšetė, kt.), įrenginio
            pavadinimas/modelis/OS/naršyklė, IP adresas.
          </li>
          <li>
            <strong>Naršyklės duomenys:</strong> naršymo ar paieškos istorija,
            naršyklės interakcijos/paspaudimai (įskaitant ant reklamų),
            naršyklės prisijungimo šaltinis (iš kokio puslapio atėjo), naršyklės
            užklausos ar kita informacija, naršymo statistiniai duomenys.
          </li>
          <li>
            <strong>Socialinio tinklo duomenys:</strong> vardas, pavardė arba
            paskyros nuoroda, el. pašto adresas, telefono numeris, IP adresas,
            įrenginio duomenys.
          </li>
        </ul>
        <h4>Kokiu teisiniu pagrindu remiamės tvarkydami jūsų informaciją?</h4>
        <p>
          Jūsų asmeninę informaciją tvarkome tik tada, kai manome, kad tai
          būtina, ir turime tam teisinį pagrindą. Todėl galime remtis:
        </p>
        <ul>
          <li>
            <strong>Sutikimas.</strong> Galime tvarkyti jūsų asmens duomenis,
            jei davėte mums leidimą (t. y. sutikimą) naudoti jūsų asmeninę
            informaciją konkrečiu tikslu (pvz., tiesioginei rinkodarai). Savo
            sutikimą galite bet kada atšaukti.
          </li>
          <li>
            <strong>Sutarties vykdymas.</strong> Jūsų asmens duomenis galime
            tvarkyti, kai manome, kad tai būtina, kad įvykdytume savo
            sutartinius įsipareigojimus jums, įskaitant mūsų Paslaugų teikimą,
            arba jūsų prašymu prieš sudarant sutartį su jumis.
          </li>
          <li>
            <strong>Teisinė prievolė.</strong> Jūsų asmens duomenis galime
            tvarkyti, jei manome, kad tai būtina siekiant laikytis savo teisinių
            įsipareigojimų, pavyzdžiui, bendradarbiauti su teisėsaugos
            institucijomis ar reguliavimo agentūromis, įgyvendinti ar ginti savo
            teises arba atskleisti jūsų informaciją kaip įrodymą teismo procese,
            kuriame dalyvaujame.
          </li>
          <li>
            <strong>Teisėti interesai.</strong> Jūsų informaciją galime
            tvarkyti, kai manome, kad tai yra pagrįstai būtina mūsų teisėtiems
            verslo interesams pasiekti, ir šie interesai nėra svarbesni už jūsų
            interesus ir pagrindines teises bei laisves.
          </li>
        </ul>
        <h4>Kiek laiko saugome jūsų informaciją?</h4>
        <p>
          Jūsų asmeninę informaciją saugosime tik tol, kol ji bus reikalinga
          šioje Privatumo politikoje nurodytiems tikslams pasiekti, išskyrus
          atvejus, kai pagal įstatymus (pvz., mokesčių, apskaitos ar kitus
          teisinius reikalavimus) reikalaujama arba leidžiama saugoti ilgesnį
          laikotarpį.
        </p>
        <p>
          Jūsų asmens duomenys saugomi atsižvelgiant į duomenų tvarkymo tikslą:
        </p>
        <ul>
          <li>
            Prekių ir (arba) paslaugų teikimo, mokėjimų administravimo tikslais
            – saugomi 10 (dešimt) metų.
          </li>
          <li>
            Tiesioginės rinkodaros tikslais – saugomi ne ilgiau kaip 2 (dvejus)
            metus nuo gauto sutikimo gavimo dienos arba iki sutikimo atšaukimo.
          </li>
          <li>
            Konkursų, akcijų, žaidimų organizavimo ir vykdymo tikslais – saugomi
            ne ilgiau kaip 2 (dvejus) metus nuo konkurso pabaigos, o prizų
            laimėjimo atveju – ne ilgiau kaip 10 (dešimt) metų nuo prizo
            įteikimo.
          </li>
          <li>
            Aptarnavimo kokybės vertinimo, tobulinimo ir užtikrinimo tikslais –
            saugomi ne ilgiau kaip 2 (dvejus) metus nuo sutikimo gavimo dienos
            arba iki sutikimo atšaukimo.
          </li>
          <li>
            Kilusių ginčų su vartotojais sprendimo tikslais – saugomi ne ilgiau
            kaip 2 (dvejus) metus nuo ginčo pabaigos, o visiško ar dalinio
            prekių grąžinimo atveju – ne ilgiau kaip 10 (dešimt) metų nuo prekių
            grąžinimo dienos.
          </li>
        </ul>
        <h4>Kada ir su kuo dalijamės jūsų asmenine informacija?</h4>
        <p>
          Mums gali prireikti dalytis jūsų asmenine informacija su šiais
          asmenimis:
        </p>
        <ul>
          <li>
            Paslaugų teikėjais ir kitais mūsų verslo partneriais. Tais atvejais,
            kai mums gali prireikti pasitelkti kitus paslaugų teikėjus,
            pavyzdžiui, kurjerius, mokėjimo tarpininkus ir pan., kad galėtume
            jums teikti Paslaugas.
          </li>
          <li>
            Priežiūros ar teisėsaugos institucijomis. Tais atvejais, kai to iš
            mūsų reikalaujama pagal įstatymus arba kai institucijos pateikia
            teisėtus prašymus.
          </li>
        </ul>
        <p>
          Atkreipkite dėmesį, kad jūsų asmeninė informacija nebus perduodama už
          Europos Ekonominės Erdvės (EEE) ribų.
        </p>
        <h4>Ar naudojame slapukus?</h4>
        <p>Taip, naudojame šiuos slapukus:</p>
        <ul>
          <li>
            <strong>Būtinieji slapukai.</strong> Šie slapukai yra būtini, kad
            galėtume suteikti jums Paslaugas, kurių iš mūsų tikitės.
            Nereikalaujame jūsų sutikimo naudoti tokius būtinuosius slapukus,
            tačiau vis tiek galite juos užblokuoti arba ištrinti.
          </li>
          <li>
            <strong>Funkciniai slapukai.</strong> Šie slapukai leidžia svetainei
            užtikrinti geresnį funkcionalumą ir personalizavimą. Juos galime
            nustatyti mes arba trečiųjų šalių paslaugų teikėjai, kurių paslaugas
            įtraukėme į savo puslapius. Jei neleisite naudoti šių slapukų, kai
            kurios arba visos šios Paslaugos gali tinkamai neveikti.
          </li>
          <li>
            <strong>Analitiniai slapukai.</strong> Šie slapukai leidžia mums
            skaičiuoti apsilankymus ir srauto šaltinius, kad galėtume įvertinti
            ir pagerinti savo svetainės veikimą. Jie padeda mums sužinoti, kurie
            puslapiai yra populiariausi ir mažiausiai populiarūs, ir pamatyti,
            kaip lankytojai juda svetainėje.
          </li>
          <li>
            <strong>Reklaminiai slapukai.</strong> Šiuos slapukus mūsų
            svetainėje gali nustatyti mūsų reklamos partneriai. Šios bendrovės
            gali juos naudoti, kad sudarytų jūsų interesų profilį ir rodytų jums
            atitinkamus skelbimus kitose svetainėse.
          </li>
          <li>
            <strong>Socialinių tinklų slapukai.</strong> Šiuos slapukus nustato
            įvairios socialinės žiniasklaidos paslaugos, kurias įtraukėme į
            svetainę, kad galėtumėte dalytis mūsų turiniu su savo draugais ir
            tinklais. Jie gali sekti jūsų naršyklę kitose svetainėse ir sudaryti
            jūsų interesų profilį. Tai gali turėti įtakos turiniui ir
            pranešimams, kuriuos matote kitose jūsų lankomose svetainėse.
          </li>
        </ul>
        <h4>Kaip galite valdyti slapukus?</h4>
        <p>
          Galite konfigūruoti naršyklę taip, kad ji atsisakytų kai kurių ar visų
          slapukų arba prieš priimdama slapukus paprašytų jūsų leidimo.
          Atkreipkite dėmesį, kad ištrynę slapukus arba išjungę būsimus slapukus
          galite negalėti naudotis tam tikromis mūsų svetainės dalimis arba
          funkcijomis. Informacijos apie tai, kaip koreguoti ar keisti naršyklės
          nustatymus, rasite adresu
          <a href="https://aboutcookies.org/">www.aboutcookies.org</a> arba
          <a href="https://allaboutcookies.org/">www.allaboutcookies.org.</a>
        </p>
        <p>
          Informaciją apie trečiųjų šalių slapukus, jų politiką ir kontrolės
          galimybes rasite tokių trečiųjų šalių slapukų politikoje.
        </p>
        <h4>Jūsų duomenų apsaugos teisės</h4>
        <p>Pagal BDAR ir kitus nacionalinius įstatymus turite šias teises:</p>
        <ul>
          <li>
            <strong>Jūsų teisė susipažinti</strong> – turite teisę prašyti mūsų
            pateikti savo asmeninės informacijos kopijas.
          </li>
          <li>
            <strong>Jūsų teisė į ištaisymą</strong> – turite teisę prašyti mūsų
            ištaisyti, jūsų manymu, netikslią asmeninę informaciją. Taip pat
            turite teisę prašyti mūsų papildyti, jūsų manymu, neišsamią
            informaciją.
          </li>
          <li>
            <strong>Jūsų teisė reikalauti ištrinti</strong> – tam tikromis
            aplinkybėmis turite teisę prašyti mūsų ištrinti jūsų asmeninę
            informaciją.
          </li>
          <li>
            <strong>Jūsų teisė apriboti duomenų tvarkymą</strong> – tam tikromis
            aplinkybėmis turite teisę prašyti mūsų apriboti jūsų asmeninės
            informacijos tvarkymą.
          </li>
          <li>
            <strong>Jūsų teisė nesutikti su duomenų tvarkymu</strong> – tam
            tikromis aplinkybėmis turite teisę nesutikti, kad jūsų asmeninė
            informacija būtų tvarkoma.
          </li>
          <li>
            <strong>Jūsų teisė į duomenų perkeliamumą</strong> – tam tikromis
            aplinkybėmis turite teisę prašyti, kad jūsų pateiktą asmeninę
            informaciją perduotume kitai organizacijai arba jums.
          </li>
          <li>
            <strong>Jūsų teisė pateikti skundą</strong> – taip pat turite teisę
            pateikti skundą atitinkamai duomenų apsaugos priežiūros
            institucijai. Lietuvoje kompetentinga institucija yra Valstybinė
            duomenų apsaugos inspekcija.
          </li>
        </ul>
        <p>
          Už naudojimąsi savo teisėmis nereikalaujama mokėti jokio mokesčio.
          Stengiamės atsakyti į visus teisėtus prašymus pagal šią Privatumo
          politiką per vieną mėnesį nuo jų gavimo. Kai kuriais atvejais
          (pavyzdžiui, jei klausimas yra ypač sudėtingas arba jei siunčiate mums
          kelis prašymus) gali prireikti daugiau nei mėnesio. Tokiais atvejais
          mes jus apie tai nuolat informuosime.
        </p>
        <p>
          Jei norite pateikti prašymą, susisiekite su mumis el. paštu
          info@kipos.lt.
        </p>
        <h4>Vaikų privatumas</h4>
        <p>
          Mūsų Paslaugos nėra skirtos jaunesniems nei 14 metų asmenims. Mes
          sąmoningai nerenkame asmeninės informacijos iš jaunesnių nei 14 metų
          asmenų. Jei esate tėvai arba globėjai ir žinote, kad jūsų vaikas
          pateikė mums asmeninius duomenis, susisiekite su mumis.
        </p>
        <p>
          Jei sužinome, kad surinkome jaunesnių nei 14 metų asmenų asmens
          duomenis, nepatikrinę tėvų sutikimo, imamės priemonių pašalinti šią
          informaciją iš savo serverių.
        </p>
        <h4>Nuorodos į kitas svetaines</h4>
        <p>
          Mūsų Paslaugose gali būti nuorodų į kitas, ne mūsų valdomas svetaines.
          Jei spustelėsite trečiosios šalies nuorodą, būsite nukreipti į tos
          trečiosios šalies svetainę. Primygtinai rekomenduojame peržiūrėti
          kiekvienos svetainės, kurioje lankotės, privatumo, slapukų politiką.
        </p>
        <p>
          Mes nekontroliuojame ir neprisiimame jokios atsakomybės už trečiųjų
          šalių svetainių ar paslaugų turinį, privatumo politiką ar praktiką.
        </p>
        <h4>Šios privatumo politikos pakeitimai</h4>
        <p>
          Pasiliekame teisę savo nuožiūra bet kuriuo metu keisti, modifikuoti,
          papildyti ir (arba) pašalinti šios Privatumo politikos dalis. Be to,
          siekiame užtikrinti, kad ji būtų nedelsiant atnaujinta, jei iš esmės
          pakeičiame savo procesus ar praktiką. Apie bet kokius pakeitimus
          informuosime jus paskelbdami naują Privatumo politiką šiame puslapyje.
        </p>
        <p>
          Patariama periodiškai peržiūrėti šią Privatumo politiką, kad
          sužinotumėte apie bet kokius jos pakeitimus. Šios Privatumo politikos
          pakeitimai įsigalioja, kai jie paskelbiami šiame puslapyje.
        </p>
        <p>
          Tikimės, kad ši Privatumo politika buvo naudinga, nes joje išdėstėme,
          kaip tvarkome jūsų asmens duomenis, ir jūsų teises juos kontroliuoti.
        </p>
      </Section>
      <Footing />
    </>
  );
};

export default PrivacyPolicy;
